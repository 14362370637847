<template>
  <div
    v-if="complaint"
    class="w-full flex flex-col justify-center items-center gap-10"
  >
    <Wrapper class="max-w-2xl">
      <div class="w-full max-w-xl">
        <h2
          v-if="replica"
          class="mb-5 text-blue-dark text-left text-2xl md:text-3xl"
        >
          <b>Replicar una queja</b>
        </h2>
        <h2
          v-if="escalate"
          class="mb-5 text-blue-dark text-left text-2xl md:text-3xl"
        >
          <b
            >Quiero tramitar mi queja ante el Defensor del Consumidor
            Financiero</b
          >
        </h2>
        <ComplaintDetailHeaderCard
          :radicado="$route.params.complaint_id"
          detail="true"
          :status="complaint.estado_cod"
        />
      </div>
      <div class="w-full">
        <form @submit.prevent="sendForm" class="max-w-xl py-6">
          <BaseTextarea
            id="reason"
            label="Argumentos"
            v-model="form.argumento"
            placeholder="Descripción motivo de la informidad"
            maxlength="1000"
          />
          <DragDrop
            id="attachment"
            @files-handler="setFiles"
            :fileList="files"
            label="Anexos"
            buttonLabel="ADJUNTAR NUEVOS SOPORTES"
          />
          <p class="my-3 text-sm text-red-500">{{ feedback }}</p>
          <div class="w-full flex flex-col gap-4">
            <a-button
              v-if="replica"
              type="primary"
              html-type="submit"
              shape="round"
              :block="true"
              :loading="loading"
            >
              FINALIZAR RÉPLICA
            </a-button>
            <a-button
              v-if="escalate"
              type="primary"
              html-type="submit"
              shape="round"
              :block="true"
              :loading="loading"
            >
              ENVIAR QUEJA AL DEFENSOR DEL CONSUMIDOR FINANCIERO
            </a-button>
            <a-button
              type="danger"
              shape="round"
              :block="true"
              ghost=""
              @click.native="$router.go(-1)"
            >
              CANCELAR
            </a-button>
            <!-- <BaseButton v-if="replica" id="replica" type="submit" label="FINALIZAR RÉPLICA" class="w-full"/>
                  <BaseButton v-if="escalate" id="escalate" type="submit" label="ENVIAR QUEJA AL DEFENSOR DEL CONSUMIDOR FINANCIERO" class="w-full"/>
                  <BaseButton id="return" label="CANCELAR" type="button" class="w-full" danger="true" @click.native="$router.go(-1)"/> -->
          </div>
        </form>
      </div>
    </Wrapper>
    <div class="w-11/12 md:w-full mt-5 md:mt-0 max-w-2xl">
      <AccordionList :items="accordionList" />
    </div>
  </div>
</template>

<script>
import checkFileSize from "@/utils/checkFileSize";
import ComplaintDetailHeaderCard from "@/components/Customer/Complaint/ComplaintDetailHeaderCard";
export default {
  components: {
    ComplaintDetailHeaderCard,
  },
  created() {},
  data() {
    return {
      accordionList: [
        {
          title: "¿Cuándo puedo hacer una réplica?",
          body: "En caso que usted quede inconforme con la respuesta dada por la entidad vigilada, puede replicar. ",
        },
        {
          title: "¿Cuál es el plazo para atender la  réplica?",
          body: "La réplica interpuesta por el consumidor financiero contra la respuesta dada por la entidad vigilada a la queja o reclamo será atendida dentro los 15 días hábiles a la recepción de la misma.",
        },
        {
          title: "¿Cuándo no puedo interponer una réplica?",
          body: "En el evento en que el consumidor financiero no presente réplica en un término de dos meses, la SFC dará por finalizado el trámite. Sin embargo, puede radicar una queja nueva.",
        },
        {
          title: "¿Cuantas veces puedo presentar una réplica?",
          body: "No existe limite para que Usted presente replicas a la queja.",
        },
        {
          title:
            "¿Que hace el Defensor del Consumidor Financiero con la queja?",
          body: "Una vez recibida la queja o reclamo, el Defensor del Consumidor Financiero decidirá si el asunto que se le somete es de su competencia o no. Dicha decisión será comunicada al consumidor y a la entidad vigilada dentro de los tres (3) días hábiles contados desde el día siguiente en que sea recibida la solicitud. Si la queja o reclamo es admitida, el Defensor del Consumidor Financiero deberá comunicar al consumidor. Si la queja o reclamo es inadmitida, el Defensor del Consumidor Financiero comunicará su decisión al consumidor financiero, indicando los motivos de la inadmisión e informando que esto no obsta para el ejercicio de las demás acciones legales existentes. El Defensor del Consumidor Financiero deberá evaluar la información aportada y resolver la queja o reclamo",
        },
      ],
      form: {
        tipo: 1,
        argumento: "",
      },
      files: [],
      reason: "",
      feedback: "",
      loading: false,
    };
  },
  methods: {
    async sendForm() {
      if (this.form.argumento == "") {
        this.loading = false;
        this.feedback = "Verifique los campos";
        return;
      } else {
        this.feedback = "";
      }
      let form = {
        ...this.form,
        codigo_queja: this.complaint.codigo_queja,
      };
      this.loading = true;
      let { data, error } = await this.$api.setReplica(form, this.files);
      this.loading = false;
      if (error) {
        this.$notification.error({
          message: "Error",
          description: "Ocurrio un error",
        });
      }
      if (data) {
        this.$router.push({
          name: "DashboardMessageDesist",
          query: {
            complaintCode: this.$route.params.complaint_id,
            type: this.form.tipo,
          },
        });
      }
    },
    setFiles(files) {
      if (checkFileSize(files)) {
        this.feedback = "Se alcanzó el tamaño máximo de archivos";
      } else {
        this.files = files;
        this.feedback = "";
      }
    },
  },
  computed: {
    replica() {
      if (this.$route.path.includes("replica")) {
        this.form.tipo = 1;
        return this.form.tipo;
      }
    },
    escalate() {
      if (this.$route.path.includes("escalate")) {
        this.form.tipo = 2;
        return this.form.tipo;
      }
    },
    complaint: function () {
      return {
        complaint_id: this.$route.params.complaint_id,
        codigo_queja: this.$route.params.complaint_id,
        estado_cod: this.$route.params.estado_cod,
      };
    },
  },
};
</script>

<style>
</style>